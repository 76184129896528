import { formatDate2 } from "../../../utility/Utils";
import {
  GET_INIT_DATA,
  GET_ALL_PRODUCTS,
  GET_SINGLE_PRODUCTS,
  GET_UNITS_CATEGORIES,
  GET_COMBO_PRODUCTS,
  GET_PRODUCT_BY_ID,
  CLEAR_SINGLE_PRODUCTS,

  ADD_SELECTED_IMG,
  REMOVE_SELECTED_IMG,

  ADD_SELECTED_PRODUCT_IMG,
  UPDATE_SELECTED_PRODUCT_IMG,
  DELETE_SELECTED_PRODUCT_IMG,
  UPDATE_SELECTED_PRODUCT,
  CLEAR_SELECTED_PRODUCT,
  APPLY_PRODUCT_FILTER,

  CLEAR_SELECTED_PRODUCT_SET,
  UPDATE_SELECTED_PRODUCT_SET,
  ADD_SELECTED_PRODUCT_SET_IMG,
  UPDATE_SELECTED_PRODUCT_SET_IMG,
  DELETE_SELECTED_PRODUCT_SET_IMG,

  PRODUCT_IS_LOADING,
  PRODUCT_LOADED,
  CREATE_SINGLE_PRODUCT_DATA,
  UPDATE_COMBO_PRODUCT_DATA,
  GET_STOCK_HISTORY,
  CLEAR_STOCK_HISTORY,
  FILTER_STOCKS_HISTORY_BASED_ON_DATE,
  GET_PARTY_WISE_REPORT,
  CLEAR_PARTY_WISE_REPORT,
  FILTER_PARTYWISE_REPORT_BASED_ON_DATE,
  EDIT_SELECTED_PRODUCT,
  SET_EDIT_SELECTED_PRODUCT,
  CREATE_EDIT_PRODUCT_DATA,
  CLEAR_EDIT_SINGLE_PRODUCTS,
  SET_COMBO_SELECTED_PRODUCT
} from "../../actions/product/index";

const initialState = {
  initDataLoaded: false,
  productLoading: false,
  productsList: [],
  singleProductsLists: [],
  comboProductsLists: [],
  singleProdListReceived: false,
  units: [],
  categories: [],
  createProductData: {
    pName: "",
    skuCode: "",
    category: "",
    length: -1,
    width: -1,
    height: -1,
    hsn: "",
    tax: "",
    image_url: "",
    primary_unit: "",
    openStockQty: -1,
    openStockPrice: -1,
    brand: "",
    style_code: "",
    color: "",
    mrp: "",
    ideal_for: "",
    low_stock_alert: "",
    size: "",
    total_qty: ""
  },
  comboProductData: {
    combo_name: "",
    combo_skucode: "",
    package_dimensions: "",
    weight: "",
    category: "",
    color: "",
    size: "",
    brand: "",
    selling_price: "",
    shipping_mode: "",
    image_url: "",
    hsn_code: "",
    gst: "",
    openStockQty: -1,
    openStockPrice: -1,
    single_products: []

  },
  // categories: {
  //   categoryList: [{ id: 1, name: "Boys" }, { id: 2, name: "Girls" }],
  //   superCategoryList: ["Men", "Women"],
  // },
  tagSpecifications: {
    tagTypes: [],
    tagLists: [],
  },
  cities: [],
  productView: {},
  selectedProduct: {
    title: "",
    description: "",
    moq: -1,
    tagList: {},
    tagIdList: "",
    deleteTagIdList: "",
    superCategoryId: "",
    categoryId: "",
    subCategoryId: "",
    imageList: [],
    addImageList: [],
    deleteImageList: [],
    isVisible: 1,
    hiddenCities: "",
    originCountry: "India"
  },
  selectedImages: [],
  selectedProductSet: {
    prodId: -1,
    description: "",
    aliasName: "",
    price: -1,
    mrp: -1,
    piecePerSet: -1,
    gst: -1,
    skuId: "",
    loadedSkuId: "",
    hsnCode: "",
    isSetVisible: 1,
    imageList: [],
    addImageList: [],
    deleteImageList: []
  },
  filters: {
    superCategory: 0,
    category: "",
    productSearch: "",
  },
  stockHistory: [],
  stockFilterHistory: [],
  partyWiseReport: [],
  partyWiseFilterReport: [],
  editSelectedProduct: {
  }
};

const product = (state = initialState, action) => {
  switch (action.type) {
    case GET_INIT_DATA: {
      return {
        ...state,
        // categories: action.init_data.categories,
        tagSpecifications: action.init_data.tagSpecifications,
        cities: action.init_data.cities,
        initDataLoaded: true
      };
    }

    //NT-INV
    case GET_SINGLE_PRODUCTS: {
      return {
        ...state,
        singleProductsLists: action.data.single_products,
        singleProdListReceived: true,

      }
    }

    //NT-INV GET UNITS
    case GET_UNITS_CATEGORIES: {
      return {
        ...state,
        units: action.data.units,
        categories: action.data.categories
      }
    }

    case GET_PARTY_WISE_REPORT: {
      let updatedPartyWiseReport = [...action.data.message].map((party, index) => {
        return {
          ...party,
          SNO: index + 1,
          created: formatDate2(party['created_ts']),
          updated: formatDate2(party['updated_ts']),

        }
      })
      return {
        ...state,
        partyWiseReport: updatedPartyWiseReport,
        partyWiseFilterReport: updatedPartyWiseReport
      }
    }

    case GET_STOCK_HISTORY: {
      let updatedStockHistory = [...action.data.stock_history].map((stockHistory, index) => {
        return {
          ...stockHistory,
          SNO: index + 1,
          created: formatDate2(stockHistory['created_ts']),
          updated: formatDate2(stockHistory['updated_ts']),

        }
      })
      return {
        ...state,
        stockFilterHistory: updatedStockHistory,
        stockHistory: updatedStockHistory
      }
    }

    case FILTER_STOCKS_HISTORY_BASED_ON_DATE: {
      return {
        ...state,
        stockFilterHistory: [...action.data]
      }
    }

    case FILTER_PARTYWISE_REPORT_BASED_ON_DATE: {
      return {
        ...state,
        partyWiseFilterReport: [...action.data]
      }
    }

    case CLEAR_STOCK_HISTORY: {
      return {
        ...state,
        stockHistory: [],
        stockFilterHistory: []
      }
    }

    case CLEAR_PARTY_WISE_REPORT: {
      return {
        ...state,
        partyWiseReport: [],
        partyWiseFilterReport: []
      }
    }

    case GET_COMBO_PRODUCTS: {
      return {
        ...state,
        comboProductsLists: action.data.combo_products,
      }
    }
    //NT-INV
    case CLEAR_SINGLE_PRODUCTS: {
      return {
        ...state,
        singleProductsLists: [],
        createProductData: {
          pName: "",
          skuCode: "",
          category: "",
          length: -1,
          width: -1,
          height: -1,
          hsn: "",
          tax: "",
          image_url: "",
          primary_unit: "",
          brand: "",
          style_code: "",
          color: "",
          mrp: "",
          ideal_for: "",
          low_stock_alert: "",
          size: "",
          total_qty: ""
        },
        comboProductData: {
          combo_name: "",
          combo_skucode: "",
          package_dimensions: "",
          weight: "",
          category: "",
          color: "",
          size: "",
          brand: "",
          selling_price: "",
          shipping_mode: "",
          image_url: "",
          hsn_code: "",
          gst: "",
          single_products: []

        }

      };
    }

    //NT-INV
    case CREATE_SINGLE_PRODUCT_DATA: {
      let { createProductData } = state;
      createProductData[action.key] = action.value;
      return {
        ...state,
        createProductData: createProductData
      }
    }


    //NT-INV
    case CLEAR_EDIT_SINGLE_PRODUCTS: {
      return {
        ...state,
        editSelectedProduct: []
      }
    }

    case CREATE_EDIT_PRODUCT_DATA: {
      let { editSelectedProduct } = state;
      editSelectedProduct[action.key] = action.value;
      return {
        ...state,
        editSelectedProduct: editSelectedProduct
      }
    }

    // NT-INV
    case UPDATE_COMBO_PRODUCT_DATA: {
      let { comboProductData } = state;
      comboProductData[action.key] = action.value;
      return {
        ...state,
        comboProductData: comboProductData,
      }

    }


    case GET_ALL_PRODUCTS: {
      return {
        ...state,
        productsList: action.data.productsList,
      };
    }

    case GET_PRODUCT_BY_ID: {
      return {
        ...state,
        productView: action.data.productList,
      };
    }

    case ADD_SELECTED_IMG: {
      let { images } = action;
      let { selectedImages } = state;
      let currentImageId = selectedImages.length;

      for (let index in images) {
        let image = images[index];
        image.id = currentImageId++;

        selectedImages.push(image);
      }

      return {
        ...state,
        selectedImages: selectedImages,
      };
    }

    case REMOVE_SELECTED_IMG: {
      let { selectedImages } = state;

      if (action.imageId === -1) {
        selectedImages.forEach(file => URL.revokeObjectURL(file.preview));
        selectedImages = [];
      } else {
        for (let index in selectedImages) {
          let img = selectedImages[index];
          if (img.id === action.imageId) {
            URL.revokeObjectURL(img.preview);
            selectedImages.splice(index, 1);
            break;
          }
        }
      }

      return {
        ...state,
        selectedImages: selectedImages,
      };
    }

    case CLEAR_SELECTED_PRODUCT: {
      return {
        ...state,
        selectedProduct: {
          title: "",
          description: "",
          moq: -1,
          tagList: {},
          tagIdList: "",
          deleteTagIdList: "",
          superCategoryId: "",
          categoryId: "",
          subCategoryId: "",
          imageList: [],
          addImageList: [],
          deleteImageList: [],
          isVisible: 1,
          hiddenCities: "",
          originCountry: "India"
        },
        productView: {},
      };
    }

    case UPDATE_SELECTED_PRODUCT: {
      let { selectedProduct } = state;
      selectedProduct[action.key] = action.value;

      return {
        ...state,
        selectedProduct: selectedProduct,
      };
    }

    case EDIT_SELECTED_PRODUCT: {
      let { editSelectedProduct } = state;
      editSelectedProduct[action.key] = action.value;

      return {
        ...state,
        editSelectedProduct
      };
    }

    case SET_EDIT_SELECTED_PRODUCT: {
      let { editSelectedProduct } = state;
      return {
        ...state,
        editSelectedProduct: {
          ...action.payload
        }
      }
    }

    case SET_COMBO_SELECTED_PRODUCT: {
      let { comboProductData } = state;
      return {
        ...state,
        comboProductData: {
          ...action.payload
        }
      }
    }

    case ADD_SELECTED_PRODUCT_IMG: {
      let { selectedProduct } = state;
      selectedProduct.imageList.push(action.imgUrl);

      return {
        ...state,
        selectedProduct: selectedProduct,
      };
    }

    case UPDATE_SELECTED_PRODUCT_IMG: {
      let { selectedProduct } = state;
      selectedProduct.addImageList.push(action.imgUrl);

      return {
        ...state,
        selectedProduct: selectedProduct,
      };
    }

    case DELETE_SELECTED_PRODUCT_IMG: {
      let { selectedProduct } = state;
      for (let index in selectedProduct.imageList) {
        let img = selectedProduct.imageList[index];
        if (img === action.imgUrl) {
          selectedProduct.imageList.splice(index, 1);
        }
      }
      selectedProduct.deleteImageList.push(action.imgUrl);

      return {
        ...state,
        selectedProduct: selectedProduct,
      };
    }

    case APPLY_PRODUCT_FILTER: {
      let { filters } = state;
      filters[action.filterKey] = action.filterValue;
      return {
        ...state,
        filters: filters,
      };
    }

    case CLEAR_SELECTED_PRODUCT_SET: {
      return {
        ...state,
        selectedProductSet: {
          prodId: -1,
          description: "",
          price: -1,
          mrp: -1,
          piecePerSet: -1,
          gst: -1,
          skuId: "",
          loadedSkuId: "",
          hsnCode: "",
          isSetVisible: 1,
          imageList: [],
          addImageList: [],
          deleteImageList: []
        }
      };
    }

    case UPDATE_SELECTED_PRODUCT_SET: {
      let { selectedProductSet } = state;
      selectedProductSet[action.key] = action.value;

      return {
        ...state,
        selectedProductSet: selectedProductSet,
      };
    }

    case ADD_SELECTED_PRODUCT_SET_IMG: {
      let { selectedProductSet } = state;
      selectedProductSet.imageList.push(action.imgUrl);

      return {
        ...state,
        selectedProductSet: selectedProductSet,
      };
    }

    case UPDATE_SELECTED_PRODUCT_SET_IMG: {
      let { selectedProductSet } = state;
      selectedProductSet.addImageList.push(action.imgUrl);

      return {
        ...state,
        selectedProductSet: selectedProductSet,
      };
    }

    case DELETE_SELECTED_PRODUCT_SET_IMG: {
      let { selectedProductSet } = state;

      for (let index in selectedProductSet.imageList) {
        let img = selectedProductSet.imageList[index];
        if (img === action.imgUrl) {
          selectedProductSet.imageList.splice(index, 1);
        }
      }
      selectedProductSet.deleteImageList.push(action.imgUrl);

      return {
        ...state,
        selectedProductSet: selectedProductSet,
      };
    }

    case PRODUCT_IS_LOADING: {
      return {
        ...state,
        productLoading: true
      }
    }

    case PRODUCT_LOADED: {
      return {
        ...state,
        productLoading: false
      }
    }

    default:
      return state;
  }
};

export default product;
