import React, { useState } from 'react';

export const LoadingContext = React.createContext(false);


export default ({ children }) => {


    const [isLoading, setIsLoading] = useState(false);

    const setLoadingHandler = (val) => {
        setIsLoading(val);
    }


    return <LoadingContext.Provider value={
        {
            isLoading,
            setLoadingHandler
        }}>
        {children}
    </LoadingContext.Provider>
}