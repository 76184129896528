import axios from "axios"
import config from "../../../configs/clientConfig";
import { toast } from "react-toastify"
import { productLoaded, productLoading } from "../product";


const UPLOAD_SALES_ORDERS_URL = '/upload/salesData'
const GET_SALES_DATA_URL = '/salesData/';
export const GET_SALES_DATA = 'GET_SALES_DATA'
export const SET_SALES_LOADING = 'SET_SALES_LOADING';
export const CLEAR_SALES_DATA = 'CLEAR_SALES_DATA';

export const uploadSalesOrdersCsv = (file) => {
    return dispatch => {
        //   dispatch({
        //     type: CLEAR_ORDER_LIST,
        //   })

        let formData = new FormData();
        formData.append('salesData', file);
        return axios.post(
            config.apiServerHost + UPLOAD_SALES_ORDERS_URL,
            formData,
            {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
        ).then(response => {
            toast.success("Uploaded Successfully!");
            console.log('RESPONSE-----------,', response?.data?.message)
            dispatch(getSalesData());
            // dispatch(getOrders());
        }).catch(error => {
            if (error.response) {
                // Request made and server responded
                console.log('error.response.data', error.response.data);
                console.log('error.response.status', error.response.status);
                console.log('error.response.headers', error.response.headers);
                toast.error(`Upload failed - ${error.response.data.error}`);
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
                toast.error("Upload failed - No response from server");
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
                toast.error("Upload failed - Internal request error");
            }
            // toast.error("Upload failed - ", error);
            // console.log('Upload failed, order details not added,', error);
            dispatch(getSalesData());
        });
    }
}

export const getSalesData = (statusCode = 'all') => {
    return dispatch => {
        dispatch({
            type: SET_SALES_LOADING,
            payload: true
        });
        axios.get(config.apiServerHost + GET_SALES_DATA_URL + statusCode).then(response => {
            console.log('Respose getsalesdata', response.data)

            dispatch({
                type: GET_SALES_DATA,
                data: response.data
            });

        }).catch(error => {
            console.log('Respose error getsalesdata', error)
            // dispatch(getOrders());
            dispatch({
                type: CLEAR_SALES_DATA
            })
            dispatch({
                type: SET_SALES_LOADING,
                payload: false
            });
        });

    }
}