import { GET_ALL_PROFORMA, GET_PROFORMA_ITEMS_BY_ID } from '../../actions/proforma/index';

const initialState = {
    proformaList: [],
    proformaItemsList: []
}

const purchase = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_PROFORMA: {
            return {
                ...state,
                proformaList: action.data.purchase_items
            }
        }
        case GET_PROFORMA_ITEMS_BY_ID: {
            return {
                ...state,
                proformaItemsList: action.data.purchase_items
            }
        }
        default:
            return state;
    }
}


export default purchase;