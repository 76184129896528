import axios from "axios"

import config from "../../../configs/clientConfig";
import { history } from "../../../history"
import { toast } from "react-toastify"

const GET_INIT_DATA_URL = "/initdata";
export const GET_SINGLE_PRODUCTS_URL = "/singleProducts";
const GET_UNITS_CAT_URL = "/unitsAndCategories";
const GET_COMBO_PRODUCTS_URL = "/comboProducts";
const GET_ALL_PRODUCTS_URL = "/getallproducts";
const GET_PRODUCT_BY_ID_URL = "/getproductbyid";
const ADD_PRODUCT_URL = "/addproduct";
const UPDATE_PRODUCT_URL = "/updateproduct";
const ADD_PRODUCT_SET_URL = "/addproductset";
const UPDATE_PRODUCT_SET_URL = "/updateproductset";
const UPLOAD_PRODUCT_IMG_URL = "/upload";
const UPLOAD_SINGLE_PRODUCTS_URL = "/upload/singleProducts";
const UPLOAD_COMBO_PRODUCTS_URL = "/upload/comboProducts";
const SAVE_SINGLE_PRODUCT_URL = "/save/singleProduct";
const UPDATE_SINGLE_PRODUCT_URL = "/update/singleProduct";
const SAVE_COMBO_PRODUCT_URL = "/save/comboProduct";
const GET_STOCK_HISTORY_URL = '/stock/history/'
const GET_PARTY_WISE_REPORT_URL = '/transactions/partywise';

export const GET_INIT_DATA = "GET_INIT_DATA";

//START NT-INV ACTIONS
export const EDIT_SELECTED_PRODUCT = 'EDIT_SELECTED_PRODUCT';
export const SET_EDIT_SELECTED_PRODUCT = 'SET_EDIT_SELECTED_PRODUCT';
export const SET_COMBO_SELECTED_PRODUCT='SET_COMBO_SELECTED_PRODUCT';
export const GET_SINGLE_PRODUCTS = "GET_SINGLE_PRODUCTS";
export const GET_UNITS_CATEGORIES = 'GET_UNITS_CATEGORIES';
export const GET_COMBO_PRODUCTS = "GET_COMBO_PRODUCTS";
export const SAVE_SINGLE_PRODUCT = 'SAVE_SINGLE_PRODUCT';
export const CLEAR_SINGLE_PRODUCTS = 'CLEAR_SINGLE_PRODUCTS';
export const CLEAR_EDIT_SINGLE_PRODUCTS='CLEAR_EDIT_SINGLE_PRODUCTS'
export const CREATE_SINGLE_PRODUCT_DATA = 'CREATE_SINGLE_PRODUCT_DATA';
export const UPDATE_COMBO_PRODUCT_DATA = 'UPDATE_COMBO_PRODUCT_DATA';
export const SAVE_COMBO_PRODUCT = 'SAVE_COMBO_PRODUCT';
export const GET_STOCK_HISTORY = 'GET_STOCK_HISTORY';
export const CLEAR_STOCK_HISTORY = 'CLEAR_STOCK_HISTORY';
export const FILTER_STOCKS_HISTORY_BASED_ON_DATE = 'FILTER_STOCKS_HISTORY_BASED_ON_DATE';
export const FILTER_PARTYWISE_REPORT_BASED_ON_DATE = 'FILTER_PARTYWISE_REPORT_BASED_ON_DATE';
export const GET_PARTY_WISE_REPORT = 'GET_PARTY_WISE_REPORT';
export const CLEAR_PARTY_WISE_REPORT = 'CLEAR_PARTY_WISE_REPORT';
export const CREATE_EDIT_PRODUCT_DATA = 'CREATE_EDIT_PRODUCT_DATA'

//ENDS NT-INV ACTIONS

export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const GET_PRODUCT_BY_ID = "GET_PRODUCT_BY_ID";

export const ADD_SELECTED_IMG = "ADD_SELECTED_IMG";
export const REMOVE_SELECTED_IMG = "REMOVE_SELECTED_IMG";

export const CLEAR_SELECTED_PRODUCT = "CLEAR_SELECTED_PRODUCT";
export const UPDATE_SELECTED_PRODUCT = "UPDATE_SELECTED_PRODUCT";
export const ADD_SELECTED_PRODUCT_IMG = "ADD_SELECTED_PRODUCT_IMG";
export const UPDATE_SELECTED_PRODUCT_IMG = "UPDATE_SELECTED_PRODUCT_IMG";
export const DELETE_SELECTED_PRODUCT_IMG = "DELETE_SELECTED_PRODUCT_IMG";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const APPLY_PRODUCT_FILTER = "APPLY_PRODUCT_FILTER";

export const CLEAR_SELECTED_PRODUCT_SET = "CLEAR_SELECTED_PRODUCT_SET";
export const UPDATE_SELECTED_PRODUCT_SET = "UPDATE_SELECTED_PRODUCT_SET";
export const ADD_SELECTED_PRODUCT_SET_IMG = "ADD_SELECTED_PRODUCT_SET_IMG";
export const UPDATE_SELECTED_PRODUCT_SET_IMG = "UPDATE_SELECTED_PRODUCT_SET_IMG";
export const DELETE_SELECTED_PRODUCT_SET_IMG = "DELETE_SELECTED_PRODUCT_SET_IMG";
export const ADD_PRODUCT_SET = "ADD_PRODUCT_SET";


export const PRODUCT_IS_LOADING = "PRODUCT_IS_LOADING";
export const PRODUCT_LOADED = "PRODUCT_LOADED";

const API_URL = process.env.REACT_APP_API_ENDPOINT;

export const productLoading = () => {
  return dispatch => {
    dispatch({
      type: PRODUCT_IS_LOADING
    });
  }
}

export const productLoaded = () => {
  return dispatch => {
    dispatch({
      type: PRODUCT_LOADED
    });
  }
}

export const getInitData = () => {
  return dispatch => {
    axios
      .get(config.apiServerHost + GET_INIT_DATA_URL)
      .then(response => {
        dispatch({
          type: GET_INIT_DATA,
          init_data: response.data
        })
      })
  }
}

//NT-INV GET /singleProducts
export const getSingleProducts = () => {
  return dispatch => {
    dispatch(productLoading());
    axios.get(config.apiServerHost + GET_SINGLE_PRODUCTS_URL).then(response => {
      dispatch({
        type: GET_SINGLE_PRODUCTS,
        data: response.data
      });
      dispatch(productLoaded());
    });
  }
}

//NT-INV GET /comboProducts
export const getComboProducts = () => {
  return dispatch => {
    dispatch(productLoading());
    axios.get(config.apiServerHost + GET_COMBO_PRODUCTS_URL).then(response => {
      dispatch({
        type: GET_COMBO_PRODUCTS,
        data: response.data
      });
      dispatch(productLoaded());
    });
  }
}

//NT-INV GET /units
export const getUnitsCategories = () => {
  return dispatch => {
    axios.get(config.apiServerHost + GET_UNITS_CAT_URL).then(response => {
      dispatch({
        type: GET_UNITS_CATEGORIES,
        data: response.data
      });
    })
  }
}

//NT-INV POST /upload/singleProducts
export const uploadSingleProducts = (file) => {
  return dispatch => {
    // dispatch({
    //   type: CLEAR_ORDER_LIST,
    // })
    dispatch(clearSingleProducts());

    const formData = new FormData();
    formData.append('singleProducts', file);
    console.log('File,', file);
    console.log('formdata,', formData);
    return axios.post(
      config.apiServerHost + UPLOAD_SINGLE_PRODUCTS_URL,
      formData,
      {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
    ).then(response => {
      toast.success("Upload Initiated");
      console.log('Upload initiated,', response);
      // dispatch(getOrders());
      dispatch(getSingleProducts());
    }).catch(error => {
      toast.error(error.response.data.error);
      // dispatch(getOrders());
      dispatch(getSingleProducts());
    });
  }
}


//NT-INV POST /upload/comboProducts
export const uploadComboProducts = (file) => {
  return dispatch => {
    // dispatch({
    //   type: CLEAR_ORDER_LIST,
    // })
    dispatch(clearSingleProducts());

    const formData = new FormData();
    formData.append('comboProducts', file);
    console.log('File,', file);
    console.log('formdata,', formData);
    return axios.post(
      config.apiServerHost + UPLOAD_COMBO_PRODUCTS_URL,
      formData,
      {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
    ).then(response => {
      toast.success("Upload Initiated");
      console.log('Upload initiated,', response);
      // dispatch(getOrders());
      dispatch(getSingleProducts());
    }).catch(error => {
      if (error.response) {
        // Request made and server responded
        console.log('error.response.data', error.response.data);
        console.log('error.response.status', error.response.status);
        console.log('error.response.headers', error.response.headers);
        toast.error(`Upload failed - ${error.response.data.error}`);
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
        toast.error("Upload failed - No response from server");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
        toast.error("Upload failed - Internal request error");
      }
      // toast.error("Upload failed - ", error);
      // console.log('Upload failed, order details not added,', error);
      // dispatch(getOrders());
      dispatch(getSingleProducts());
    });
  }
}

export const getStockHistory = (skucode) => {
  return dispatch => {
    dispatch(productLoading());
    axios.get(API_URL + GET_STOCK_HISTORY_URL + skucode).then(response => {
      dispatch({
        type: GET_STOCK_HISTORY,
        data: response.data
      })
      console.log(`Response getStockHistory for ${skucode} :-`, response.data)
      dispatch(productLoaded());
    })
  }
}

export const getPartyWiseReport = (skucode) => {
  return dispatch => {
    dispatch(productLoading());
    axios.post(API_URL + GET_PARTY_WISE_REPORT_URL, {
      skucode
    }).then(response => {
      dispatch({
        type: GET_PARTY_WISE_REPORT,
        data: response.data
      })
      console.log(`Response getPartyWiseReport for ${skucode} :-`, response.data)
      dispatch(productLoaded());
    })
  }
}


export const clearPartyWiseReport = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_PARTY_WISE_REPORT
    })
  }
}

export const filterStocksBasedOnDate = (fromDate, toDate) => {

  let today = new Date();
  return (dispatch, getState) => {
    // dispatch(getStockHistory(stock?.["skucode"]));

    let data = getState().productApp.product.stockHistory;

    console.log('fromDate', new Date(fromDate))
    console.log('toDate', new Date(toDate))
    console.log('today', today)

    if (fromDate !== null && toDate !== null) {
      data = data.filter(
        (obj) => {
          return new Date(obj?.['created_ts'].substring(0, 19)).getTime() >= fromDate.getTime() && new Date(obj?.['created_ts'].substring(0, 19)).getTime() <= toDate.getTime()
        }
      )
    }

    dispatch({
      type: FILTER_STOCKS_HISTORY_BASED_ON_DATE,
      data: [...data]
    })
  }

}

export const filterPartywiseReportBasedOnDate = (fromDate, toDate) => {

  let today = new Date();
  return (dispatch, getState) => {
    // dispatch(getStockHistory(stock?.["skucode"]));

    let data = getState().productApp.product.partyWiseReport;

    console.log('fromDate', new Date(fromDate))
    console.log('toDate', new Date(toDate))
    console.log('today', today)

    if (fromDate !== null && toDate !== null) {
      data = data.filter(
        (obj) => {
          return new Date(obj?.['created_ts'].substring(0, 19)).getTime() >= fromDate.getTime() && new Date(obj?.['created_ts'].substring(0, 19)).getTime() <= toDate.getTime()
        }
      )
    }

    dispatch({
      type: FILTER_PARTYWISE_REPORT_BASED_ON_DATE,
      data: [...data]
    })
  }

}

export const clearStockHistory = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_STOCK_HISTORY
    })
  }
}

//NT-INV POST /save/singleProduct
export const saveProduct = (value) => {
  return (dispatch, getState) => {
    // dispatch(productLoading());
    axios
      // .post(config.apiServerHost+SAVE_SINGLE_PRODUCT_URL, getState().productApp.product.selectedProduct)
      .post(config.apiServerHost + SAVE_SINGLE_PRODUCT_URL, value)
      .then(response => {
        // let { prodId } = response.data;
        // dispatch(clearSelectedProduct());
        console.log('Response saveProduct ', response);
        // dispatch(productLoaded());
        toast.success("Product Created successfully")
        history.push("/order/details");
      }).catch(error => {
        // dispatch(productLoaded());
        toast.error("Failed to create product");
      });
    dispatch(clearSingleProducts())
  }
}

//NT-INV POST /update/singleProduct
export const updateSingleProduct = (value) => {
  return (dispatch, getState) => {
    // dispatch(productLoading());
    axios
      // .post(config.apiServerHost+SAVE_SINGLE_PRODUCT_URL, getState().productApp.product.selectedProduct)
      .put(config.apiServerHost + UPDATE_SINGLE_PRODUCT_URL, value)
      .then(response => {
        // let { prodId } = response.data;
        // dispatch(clearSelectedProduct());
        console.log('Response updateSingleProduct ', response);
        // dispatch(productLoaded());
        toast.success("Product updated successfully")
        history.push("/order/details");
      }).catch(error => {
        // dispatch(productLoaded());
        toast.error("Failed to update product");
      });
    dispatch(clearEditSingleProducts())
  }
}
//NT-INV POST /save/comboProduct
export const saveComboProduct = (value) => {
  return (dispatch, getState) => {
    // dispatch(productLoading());
    axios
      // .post(config.apiServerHost+SAVE_COMBO_PRODUCT_URL, getState().productApp.product.selectedProduct)
      .post(config.apiServerHost + SAVE_COMBO_PRODUCT_URL, value)
      .then(response => {
        // let { prodId } = response.data;
        // dispatch(clearSelectedProduct());
        console.log('Response saveComboProduct ', response);
        // dispatch(productLoaded());

        // dispatch(clearSingleProducts())
        toast.success("Combo Product Created successfully")
        history.push("/order/details");
      }).catch(error => {
        // dispatch(productLoaded());


        toast.error("Failed to create combo product");
      })
    dispatch(clearSingleProducts());
  }
}

//NT-INV
export const updateCreateProductData = (key, value) => {
  return dispatch => {
    dispatch({
      type: CREATE_SINGLE_PRODUCT_DATA,
      key: key,
      value: value
    });
  }
}

export const updateEditProductData = (key, value) => {
  return dispatch => {
    dispatch({
      type: CREATE_EDIT_PRODUCT_DATA,
      key: key,
      value: value
    });
  }
}

//NT-INV
export const updateComboProductData = (key, value) => {
  return dispatch => {
    dispatch({
      type: UPDATE_COMBO_PRODUCT_DATA,
      key: key,
      value: value
    });
  }
}

export const clearSingleProducts = () => {
  return dispatch => {

    dispatch(productLoading());
    dispatch({
      type: CLEAR_SINGLE_PRODUCTS,
    });
    dispatch(productLoaded());
  };
}

export const clearEditSingleProducts = () => {
  return dispatch => {

    dispatch(productLoading());
    dispatch({
      type: CLEAR_EDIT_SINGLE_PRODUCTS,
    });
    dispatch(productLoaded());
  };
}

export const getAllProducts = () => {
  let user = {
    'userId': 'admin'
  }
  return dispatch => {
    dispatch(productLoading());

    axios
      .post(config.apiServerHost + GET_ALL_PRODUCTS_URL, user)
      .then(response => {
        dispatch({
          type: GET_ALL_PRODUCTS,
          data: response.data
        })
        dispatch(productLoaded());
      })
  }
}

export const getProductById = (prod_id) => {
  let user = {
    'userId': 'admin'
  }
  return dispatch => {
    axios
      .post(config.apiServerHost + GET_PRODUCT_BY_ID_URL + "/" + prod_id, user)
      .then(response => {
        dispatch({
          type: GET_PRODUCT_BY_ID,
          data: response.data
        })
      })
  }
}

export const addSelectedImages = (images) => {
  return dispatch => {
    dispatch({
      type: ADD_SELECTED_IMG,
      images: images
    })
  }
}

export const removeSelectedImages = (imageId) => {
  return dispatch => {
    dispatch({
      type: REMOVE_SELECTED_IMG,
      imageId: imageId
    })
  }
}

export const clearSelectedProduct = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_SELECTED_PRODUCT,
    })
    dispatch(removeSelectedImages(-1))
  }
}

export const updateSelectedProduct = (key, value) => {
  return dispatch => {
    dispatch({
      type: UPDATE_SELECTED_PRODUCT,
      key: key,
      value: value
    })
  }
}

export const setEditSelectedProduct = (data) => {
  return dispatch => {
    dispatch({
      type: SET_EDIT_SELECTED_PRODUCT,
      payload: data
    })
  }
}

export const editSelectedProduct = (key, value) => {
  return dispatch => {
    dispatch({
      type: UPDATE_SELECTED_PRODUCT,
      key: key,
      value: value
    })
  }
}

export const setComboSelectedProduct = (data) => {
  return dispatch => {
    dispatch({
      type: SET_COMBO_SELECTED_PRODUCT,
      payload: data
    })
  }
}

export const addSelectedProductImg = (imgUrl) => {
  return dispatch => {
    dispatch({
      type: ADD_SELECTED_PRODUCT_IMG,
      imgUrl: imgUrl
    })
  }
}

export const updateSelectedProductImg = (imgUrl) => {
  return dispatch => {
    dispatch({
      type: UPDATE_SELECTED_PRODUCT_IMG,
      imgUrl: imgUrl
    })
  }
}

export const deleteSelectedProductImg = (imgUrl) => {
  return dispatch => {
    dispatch({
      type: DELETE_SELECTED_PRODUCT_IMG,
      imgUrl: imgUrl
    })
  }
}


export const addProduct = () => {
  return (dispatch, getState) => {
    dispatch(productLoading());
    axios
      .post(config.apiServerHost + ADD_PRODUCT_URL, getState().productApp.product.selectedProduct)
      .then(response => {
        let { prodId } = response.data;
        dispatch(clearSelectedProduct());
        dispatch(productLoaded());
        toast.success("Product Created")
        history.push("/inventory/product/view/" + prodId);
      }).catch(error => {
        dispatch(productLoaded());
        toast.error("Failed to add product");
      })
  }
}

export const updateProduct = (prodId) => {
  return (dispatch, getState) => {
    dispatch(productLoading());
    axios
      .put(config.apiServerHost + UPDATE_PRODUCT_URL + "/" + prodId, getState().productApp.product.selectedProduct)
      .then(response => {
        dispatch(clearSelectedProduct());
        dispatch(productLoaded());
        toast.success("Product Updated")
        history.push("/inventory/product/view/" + prodId);
      }).catch(error => {
        dispatch(productLoaded());
        toast.error("Failed to update product");
      })
  }
}

export const filterOnProducts = (filterKey, filterValue) => {
  return dispatch => {
    dispatch({
      type: APPLY_PRODUCT_FILTER,
      filterKey: filterKey,
      filterValue: filterValue
    });
  }
}

export function uploadImage(imgFile, callback, isSmallImage) {
  let imageSuffix = "_lg";
  if (isSmallImage) {
    imageSuffix = "_sm"
  }

  return dispatch => {
    let formData = new FormData();
    formData.append('avatar', imgFile);
    return axios.post(
      config.imgServerHost + UPLOAD_PRODUCT_IMG_URL + '?name=inventory',
      formData,
      {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
    ).then(response => {
      let imgArray = response.data.images;
      for (let i = 0; i < imgArray.length; i++) {
        if (imgArray[i].includes(imageSuffix)) {
          dispatch(callback(imgArray[i]));
          break;
        }
      }
    }
    );
  }
}

export const clearSelectedProductSet = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_SELECTED_PRODUCT_SET,
    })
    dispatch(removeSelectedImages(-1))
  }
}

export const updateSelectedProductSet = (key, value) => {
  return dispatch => {
    dispatch({
      type: UPDATE_SELECTED_PRODUCT_SET,
      key: key,
      value: value
    })
  }
}

export const addSelectedProductSetImg = (imgUrl) => {
  return dispatch => {
    dispatch({
      type: ADD_SELECTED_PRODUCT_SET_IMG,
      imgUrl: imgUrl
    })
  }
}

export const updateSelectedProductSetImg = (imgUrl) => {
  return dispatch => {
    dispatch({
      type: UPDATE_SELECTED_PRODUCT_SET_IMG,
      imgUrl: imgUrl
    })
  }
}

export const deleteSelectedProductSetImg = (imgUrl) => {
  return dispatch => {
    dispatch({
      type: DELETE_SELECTED_PRODUCT_SET_IMG,
      imgUrl: imgUrl
    })
  }
}

export const addProductSet = () => {
  return (dispatch, getState) => {
    dispatch(productLoading());

    let { prodId } = getState().productApp.product.selectedProductSet;
    axios
      .post(config.apiServerHost + ADD_PRODUCT_SET_URL, getState().productApp.product.selectedProductSet)
      .then(response => {
        dispatch(clearSelectedProductSet());
        dispatch(getProductById(prodId))
        dispatch(productLoaded())
        toast.success("Product Set Created")
      })
      .catch(error => {
        dispatch(productLoaded())
        toast.error("Product Set Creation Failed")
      })
  }
}

export const updateProductSet = (prodSetId) => {
  return (dispatch, getState) => {
    dispatch(productLoading());

    let { prodId } = getState().productApp.product.selectedProductSet;
    axios
      .put(config.apiServerHost + UPDATE_PRODUCT_SET_URL + "/" + prodSetId, getState().productApp.product.selectedProductSet)
      .then(response => {
        dispatch(clearSelectedProductSet());
        dispatch(getProductById(prodId));
        dispatch(productLoaded())
        toast.success("Product Set Updated")
      })
      .catch(error => {
        dispatch(productLoaded())
        toast.error("Product Set Update Failed")
      })
  }
}

