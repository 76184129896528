import { UPDATE_PURCHASE, GET_PURCHASE, CLEAR_PURCHASE } from '../../actions/purchase/index';

const initialState = {
    purchaseLoading: false,
    purchase: {
        id: 24,
        vendor_invoice_id: "",
        buyer_id: "",
        invoice_number: "",
        total: "",
        taxable_amount: "",
        sub_total: "",
        grand_total: "",
        roundoff: "",
        is_settled: "",
        purchase_date: "",
        remaining_amt: "",
        purchase_item_id: "",
        purchase_info_id: "",
        prod_id: "",
        hsncode: "",
        qty: "",
        price: "",
        tax: "",
        purchase_item_total: "",
        stock: "",
        created_ts: ""
    },
    purchaseList: [],
}

const purchase = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_PURCHASE: {
            return state;
            // let { vendor } = state;
            // vendor[action.key] = action.value;
            // return {
            //     ...state,
            //     vendor: vendor
            // }
        }
        case GET_PURCHASE: {
            return {
                ...state,
                purchaseList: action.data.purchase_items
            }
        }
        case CLEAR_PURCHASE: {
            return {
                ...state,
                purchaseList: []
            }
        }
        default:
            return state;
    }
}


export default purchase;