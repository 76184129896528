import axios from "axios"
import config from "../../../configs/clientConfig";
import { history } from "../../../history"
import { toast } from "react-toastify"
import { productLoaded, productLoading } from "../product";

const ADD_PURCHASE_URL = '/addPurchaseItems';
const GET_PURCHASE_URL = '/getPurchaseItems';
const UPDATE_PURCHASE_URL = '/updatePurchaseItems/';

export const ADD_PURCHASE = 'ADD_PURCHASE';
export const GET_PURCHASE = 'GET_PURCHASE';
export const UPDATE_PURCHASE = 'UPDATE_PURCHASE';
export const CLEAR_PURCHASE = 'CLEAR_PURCHASE';

export const addPurchaseItems = (purchaseInfo) => {
    return dispatch => {
        dispatch(productLoading());
        axios.post(config.apiServerHost + ADD_PURCHASE_URL, purchaseInfo).
            then((response) => {
                console.log('Response purchase add', response);
                toast.success("Purchase details added successfully!")
                history.push('/purchase/view')
            }).catch((error) => {
                if (error.response) {
                    // Request made and server responded
                    console.log('error.response.data', error.response.data);
                    console.log('error.response.status', error.response.status);
                    console.log('error.response.headers', error.response.headers);
                    toast.error(`Failed to add - ${error.response.data.error}`);
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log(error.request);
                    toast.error("Failed to add - No response from server");
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                    toast.error("Failed to add - Internal request error");
                }
            });
        dispatch(productLoaded());
    }
}

export const getPurchaseItems = () => {
    return dispatch => {
        dispatch(productLoading());
        axios.get(config.apiServerHost + GET_PURCHASE_URL).
            then((response) => {
                dispatch({
                    type: GET_PURCHASE,
                    data: response.data
                })
                console.log('Response get purchase ', response);

                dispatch(productLoaded());
                // toast.success("Purchase details added successfully!")
                // history.push('/ecommerce-dashboard')
            }).catch((error) => {
                toast.error("Failed to get purchase items ", error);
            });
    }
}

export const updatePurchaseItems = (id, data) => {
    return dispatch => {
        dispatch(productLoading());
        axios.put(config.apiServerHost + UPDATE_PURCHASE_URL + id, data).
            then((response) => {
                console.log('Response UPDATE purchase ', response);

                // dispatch(productLoaded());
            toast.success("Purchase details updated successfully!");
            console.log('Updated purchase item', data);
            //let updatedPurchaseItem = [{...data, prod_id: data.purchase_items.prod_id, hsncode: data.purchase_items.hsncode, qty: data.purchase_items.qty, price: data.purchase_items.price, tax: data.purchase_items.tax, purchase_item_total: data.purchase_items.total}]
            let purchaseInfo = data.purchase_info
            let buyer = data.purchase_info.buyer_name;
            console.log('info',purchaseInfo)
            let purchaseList = purchaseInfo.purchase_items.map((purchaseItem)=>{
                return {
                    grand_total: purchaseInfo.grand_total,
                    buyer_id: purchaseInfo.buyer_id,
                    id: purchaseInfo.purchase_info_id,
                    hsncode: purchaseItem.hsncode,
                    prod_skucode: purchaseItem.prod_id,
                    purchase_date: purchaseInfo.purchase_date,//"2021-11-29T18:30:00.000Z",
                    purchase_info_id: purchaseInfo.purchase_info_id,
                    purchase_item_id: 5,
                    purchase_item_total: purchaseItem.total,
                    qty: purchaseItem.qty,
                    price: purchaseItem.price,
                    stock: purchaseItem.stock,
                    tax: purchaseItem.tax,
                    invoice_number: purchaseInfo.invoice_number,
                    vendor_invoice_id: purchaseInfo.vendor_invoice_id,
                    remaining_amt: purchaseInfo.remaining_amt,
                    roundoff: purchaseInfo.roundoff,
                    sub_total: purchaseInfo.sub_total,
                    taxable_amount: purchaseInfo.taxable_amount,
                    total: purchaseInfo.total,
                    is_settled: purchaseInfo.is_settled,
                }
            })
            dispatch(productLoaded());
            history.push({
                pathname: `/purchase/view/${data.purchase_info.purchase_info_id}`,
                state:{
                    data: purchaseList,
                    buyerName: buyer
                }
            });
            //history.push('/ecommerce-dashboard')
            }).catch((error) => {
                toast.error("Failed to update purchase item payment ", error);
            });
    }
}

export const clearPurchaseItems = () => {
    return dispatch => {
        dispatch({ type: CLEAR_PURCHASE });
    }
}