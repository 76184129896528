import { UPDATE_VENDOR,GET_VENDORS, CLEAR_VENDORS } from '../../actions/vendor/index';

const initialState = {
    vendorLoading: false,
    vendor: {
        name: "",
        email: "",
        mobile: "",
        addr_line1: "",
        addr_line2: "",
        addr_line3: "",
        town: "",
        district: "",
        state: "",
        gstin: "",
        pincode: ""
    },
    vendorsList:[],
}

const vendor = (state=initialState, action) => {
    switch (action.type) {
        case UPDATE_VENDOR: {
            let { vendor } = state;
            vendor[action.key] = action.value;
            return {
                ...state,
                vendor:vendor
            }
        }
        case GET_VENDORS: {
            return {
                ...state,
                vendorsList:action.data.vendor_products
            }
        }
        case CLEAR_VENDORS: {
            return {
                ...state,
                vendorsList:[]
            }
        }
        default:
            return state;
    }
}


export default vendor;