import axios from "axios"
import config from "../../../configs/clientConfig";
import { history } from "../../../history"
import { toast } from "react-toastify"
import { productLoaded, productLoading } from "../product";

const ADD_PAYMENT_URL = '/addPurchasePayment';
const GET_PAYMENT_URL = '/getPurchasePayments';

export const GET_PAYMENT = 'GET_PAYMENT';
// export const UPDATE_PURCHASE = 'UPDATE_PURCHASE';
export const CLEAR_PAYMENT = 'CLEAR_PAYMENT';

export const addPurchasePayment = (purchase_payment) => {
    return dispatch => {
        dispatch(productLoading());
        axios.post(config.apiServerHost + ADD_PAYMENT_URL, purchase_payment).
            then((response) => {
                console.log('Response PAYMENT add', response);
                toast.success("Payment details recorded successfully!")
                history.push('/payment/view')
            }).catch((error) => {
                toast.error("Failed to record Payment ", error);
            });
        dispatch(productLoaded());
    }
}

export const getPaymentItems = () => {
    return dispatch => {
        dispatch(productLoading());
        axios.get(config.apiServerHost + GET_PAYMENT_URL).
            then((response) => {
                dispatch({
                    type: GET_PAYMENT,
                    data: response.data
                })
                console.log('Response get payment ', response);

                dispatch(productLoaded());
                // toast.success("Purchase details added successfully!")
                // history.push('/ecommerce-dashboard')
            }).catch((error) => {
                toast.error("Failed to get payment items ", error);
            });
    }
}

export const clearPaymentItems = () => {
    return dispatch => {
        dispatch({ type: CLEAR_PAYMENT });
    }
}