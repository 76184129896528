import axios from "axios"

import config from "../../../configs/clientConfig";
import { toast } from "react-toastify"

import {
  getInitData
} from "../product/index"

const ADD_CATEGORY_URL = "/addcategory";
const UPDATE_CATEGORY_URL = "/updatecategory";
const DELETE_CATEGORY_URL = "/deletecategory";

const ADD_BANNERS_URL = "/addbanners";
const GET_ALL_BANNERS_URL = "/getallbanners";
const DELETE_ALL_BANNERS_URL = "/deleteallbanners";

export const UPDATE_SELECTED_CATEGORY = "UPDATE_SELECTED_CATEGORY";
export const ADD_SELECTED_CATEGORY_IMG = "ADD_SELECTED_CATEGORY_IMG";
export const REMOVE_SELECTED_CATEGORY_IMG = "REMOVE_SELECTED_CATEGORY_IMG";
export const CLEAR_SELECTED_CATEGORY = "CLEAR_SELECTED_CATEGORY";

export const ADD_BANNER_IMG = "ADD_BANNER_IMG";
export const REMOVE_BANNER_IMG = "REMOVE_BANNER_IMG";
export const GET_ALL_BANNERS = "GET_ALL_BANNERS";
export const DELETE_ALL_BANNERS = "DELETE_ALL_BANNERS";

export const updateSelectedCategory = (key,value) => {
  return dispatch => {
    dispatch({
      type: UPDATE_SELECTED_CATEGORY,
      key: key,
      value: value
    })
  }
}

export const addSelectedCategoryImgUrl = (imageUrl) => {
  return dispatch => {
    dispatch(updateSelectedCategory("imageUrl", imageUrl));
  }
}

export const addSelectedCategoryImg = (image) => {
  return dispatch => {
    dispatch({
      type: ADD_SELECTED_CATEGORY_IMG,
      image: image
    })
  }
}

export const removeSelectedCategoryImg = () => {
  return dispatch => {
    dispatch({
      type: REMOVE_SELECTED_CATEGORY_IMG
    })
  }
}

export const addCategory = () => {
  return (dispatch, getState) => {
    axios
      .post(config.apiServerHost+ADD_CATEGORY_URL, getState().manageApp.manage.selectedCategory)
      .then(response => {
        dispatch(clearSelectedCategory());
        dispatch(getInitData());
      })
  }
}

export const updateCategory = () => {
  return (dispatch, getState) => {
    axios
      .put(config.apiServerHost+UPDATE_CATEGORY_URL, getState().manageApp.manage.selectedCategory)
      .then(response => {
        dispatch(clearSelectedCategory());
        dispatch(getInitData());
      })
  }
}

export const deleteCategory = (categoryId) => {
  return dispatch => {
    axios
      .delete(config.apiServerHost+DELETE_CATEGORY_URL+"/"+categoryId)
      .then(response => {
        dispatch(getInitData());
      })
  }
}

export const clearSelectedCategory = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_SELECTED_CATEGORY
    });
    dispatch(removeSelectedCategoryImg());
  }
}

export const getAllBanners = (categoryId) => {
  return dispatch => {
    axios.get(config.apiServerHost+GET_ALL_BANNERS_URL+"?category="+categoryId)
      .then(response => {
        dispatch({
          type: GET_ALL_BANNERS,
          data: response.data
        })
    })
  }
}

export const addBanners = (categoryId) => {
  return (dispatch, getState) => {
    console.log(getState().manageApp.manage.bannerUrls);
    axios.post(config.apiServerHost+ADD_BANNERS_URL,{
      imageList: getState().manageApp.manage.bannerUrls,
      category: categoryId
    }).then(response => {
      dispatch({
        type: DELETE_ALL_BANNERS
      });
      toast.success("Banner Uploaded")
      dispatch(getAllBanners(categoryId))
    }).catch(err => {
      toast.success("Banner Upload Failed")
    });
  }
}

export const deleteAllBanners = (categoryId) => {
  return (dispatch, getState) => {
    axios.delete(config.apiServerHost+DELETE_ALL_BANNERS_URL+"?category="+categoryId)
      .then(response => {
        console.log("Deleted successfully");
        dispatch({
          type: DELETE_ALL_BANNERS
        });
        dispatch(getAllBanners(categoryId))
    })
  }
}

export const addBannerImage = (bannerUrl) => {
  return dispatch => {
    dispatch({
      type: ADD_BANNER_IMG,
      url: bannerUrl
    });
  }
}

export const removeBannerImage = (bannerUrl) => {
  return dispatch => {
    dispatch({
      type: REMOVE_BANNER_IMG,
      url: bannerUrl
    });
  }
}
