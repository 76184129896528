import {
  GET_ALL_USERS, SET_DEVELOPER
} from "../../actions/user/index";

const initialState = {
  usersList: [],
  isDev:false
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_USERS: {
      return {
        ...state,
        usersList: action.data.usersList,
      };
    }
      
    case SET_DEVELOPER: {
      return {
        ...state,
        isDev: !state.isDev
      };
      }

    default:
      return state;
  }
};

export default user;
