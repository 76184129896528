import axios from "axios";
import React, { useContext, useState } from "react";
import config from "../configs/clientConfig";
import { LoadingContext } from "./LoadingContext";

const GET_PAYMENT_URL = '/getPurchasePayments';

export const PaymentContext = React.createContext({
    payments: [],
    fetchPayments: () => { }
});


export default props => {


    const [paymentLists, setPaymentLists] = useState([
    ]);
    const { setLoadingHandler } = useContext(LoadingContext);


    const fetchPaymentLists = () => {
        setLoadingHandler(true);
        axios.get(config.apiServerHost + GET_PAYMENT_URL).
            then((response) => {

                console.log('Response get paymentsss', response);
                if (response.statusText === 'OK') {
                    setPaymentLists(response.data.vendor_products);
                }
                else {
                    setPaymentLists(null);
                }

                setLoadingHandler(false);

            }).catch((error) => {
                console.log('Catch error get payment ', error);
                setLoadingHandler(false);
            });
    }

    const clearPaymentLists = () => {
        setPaymentLists([]);
    }

    return (
        <PaymentContext.Provider value={{
            payments: paymentLists,
            fetchPaymentLists,
            clearPaymentLists
        }}>
            {props.children}
        </PaymentContext.Provider>
    );
}




