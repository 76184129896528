import axios from "axios"
import config from "../../../configs/clientConfig";

const GET_ALL_USERS_URL = "/getallusers";
const TOGGLE_USER_VERIFICATION_URL = "/toggleuserverification";
const TOGGLE_AGENT_VERIFICATION_URL = "/toggleagentverification"

export const GET_ALL_USERS = "GET_ALL_USERS";
export const SET_DEVELOPER = 'SET_DEVELOPER';

export const getAllUsers = () => {
  return dispatch => {
    axios
      .get(config.apiServerHost+GET_ALL_USERS_URL)
      .then(response => {
        dispatch({
          type: GET_ALL_USERS,
          data: response.data
        })
      })
  }
}

export const toggleUserVerification = (userId) => {
  return dispatch => {
    axios
      .get(config.apiServerHost+TOGGLE_USER_VERIFICATION_URL+"/"+userId)
      .then(response => {
        dispatch(getAllUsers());
      })
  }
}

export const toggleAgentVerification = (userId) => {
  return dispatch => {
    axios
      .get(config.apiServerHost+TOGGLE_AGENT_VERIFICATION_URL+"/"+userId)
      .then(response => {
        dispatch(getAllUsers());
      })
  }
}

export const setDeveloper = () => {
  return dispatch => {
    dispatch({type:SET_DEVELOPER})
  }
}
