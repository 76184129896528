import axios from "axios"
import config from "../../../configs/clientConfig";
import { history } from "../../../history"
import { toast } from "react-toastify"
import { productLoaded, productLoading } from "../product";

const ADD_CUSTOMER_URL = '/add/seller';
export const GET_CUSTOMERS_URL = '/sellers';

export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const GET_CUSTOMERS = 'GET_CUSTOMERS';
// export const CLEAR_VENDORS = 'CLEAR_VENDORS';

export const addCustomerData = (customer, path) => {
    return dispatch => {
        productLoading();
        axios.post(config.apiServerHost + ADD_CUSTOMER_URL, customer).
            then((response) => {
                console.log('Response add customer', response);
                toast.success("customer added successfully!")
                if (path === '/customer/add')
                    history.push('/vendor/view')

            }).catch((error) => {
                toast.error("Failed to create customer", error);
            });
        productLoaded();
    }
}


export const updateCustomer = (key, value) => {
    return dispatch => {
        dispatch({
            type: UPDATE_CUSTOMER,
            key: key,
            value: value
        })

    }
}

export const getCustomers = () => {
    return dispatch => {
        dispatch(productLoading());
        axios.get(config.apiServerHost + GET_CUSTOMERS_URL).then(response => {
            dispatch({
                type: GET_CUSTOMERS,
                data: response.data
            })
            console.log('Response GET_CUSTOMERS -', response.data)
            dispatch(productLoaded());
        })
    }
}

// export const clearVendors = () => {
//     return dispatch => {
//         dispatch({ type: CLEAR_VENDORS })
//     }
// }