import { GET_CUSTOMERS, UPDATE_CUSTOMER } from '../../actions/customer/index';

const initialState = {
    customerLoading: false,
    customers: {
        sellingPlatform: "",
        name: "",
        email: "",
        mobile: "",
        shopNo: "",
        town: "",
        district: "",
        state: "",
        gstin: "",
        pincode: ""
    },
    customersList: [],
}

const customers = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_CUSTOMER: {
            let { customers } = state;
            customers[action.key] = action.value;
            return {
                ...state,
                customers
            }
        }
        case GET_CUSTOMERS: {
            return {
                ...state,
                customersList: action.data.sellers_info
            }
        }
        // case CLEAR_VENDORS: {
        //     return {
        //         ...state,
        //         vendorsList:[]
        //     }
        // }
        default:
            return state;
    }
}


export default customers;