import { formatDate2 } from '../../../utility/Utils';
import { CLEAR_SALES_DATA, GET_SALES_DATA, SET_SALES_LOADING } from '../../actions/sales-orders';

const initialState = {
    salesLoading: false,
    salesorders: {
        id: -1,
        order_id: "",
        company_name: "",
        selling_platform: null,
        total_price: -1,
        total_qty: -1,
        status_code: -1,
        sales_items: []
    },
    salesList: [],
}

const salesorders = (state = initialState, action) => {
    switch (action.type) {
        case SET_SALES_LOADING: {
            return {
                ...state,
                salesLoading: action.payload
            }
        }
        case GET_SALES_DATA: {
            let updatedData = action.data.sales_data.map(d => {
                let updatedD = d;
                updatedD['created_ts'] = formatDate2(d['created_ts']);
                updatedD['updated_ts'] = formatDate2(d['updated_ts']);
                updatedD['sales_items'] = d['sales_items'].map(data => {

                    let updatedData = data;
                    updatedData['created_ts'] = formatDate2(data['created_ts']);
                    updatedData['updated_ts'] = formatDate2(data['updated_ts']);

                    return updatedData


                })


                return updatedD;
            })
            return {
                ...state,
                salesLoading: false,
                salesList: updatedData
            }
        }

        case CLEAR_SALES_DATA: {
            return {
                ...state,
                salesList: []
            }
        }
        default:
            return state;
    }
}


export default salesorders;