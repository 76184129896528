import React from "react"
import * as Icon from "react-feather"
import Inventory2Icon from '@mui/icons-material/Inventory2';
import GroupIcon from '@mui/icons-material/Group';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AssessmentIcon from '@mui/icons-material/Assessment';
import FactCheckIcon from '@mui/icons-material/FactCheck';

const nTNavConfig = [

  {
    id: "dashboard",
    title: "Dashboard",
    type: "collapse",
    icon: <Icon.Home size={20} />,
    // badge: "warning",
    // badgeText: "2",
    children: [
      {
        id: "analyticsDash",
        title: "Analytics",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/"
      },
      {
        id: "eCommerceDash",
        title: "eCommerce",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin"],
        navLink: "/ecommerce-dashboard"
      }
    ]
  },
  //   {
  //     id: "dashboard",
  //     title: "Dashboard",
  //     type: "item",
  //     icon: <Icon.Home size={20} />,
  //     permissions: ["admin", "editor"],
  //     navLink: "/dashboard",
  //   },
  //   {
  //     type: "groupHeader",
  //     groupTitle: "USER"
  //   },
  //   {
  //     id: "user",
  //     title: "User",
  //     type: "collapse",
  //     icon: <Icon.User size={20} />,
  //     children: [
  //       {
  //         id: "manageUser",
  //         title: "Manage User",
  //         type: "item",
  //         icon: <Icon.Circle size={12} />,
  //         permissions: ["admin", "editor"],
  //         navLink: "/user/list"
  //       },
  //       {
  //         id: "manageAgent",
  //         title: "Manage Agent",
  //         type: "item",
  //         icon: <Icon.Circle size={12} />,
  //         permissions: ["admin", "editor"],
  //         navLink: "/agent/list"
  //       }
  //     ]
  //   },
  //   {
  //     type: "groupHeader",
  //     groupTitle: "PRODUCT"
  //   },
  // {
  //   id: "inventory",
  //   title: "Inventory",
  //   type: "collapse",
  //   icon: <Icon.ShoppingCart size={20} />,
  //   children: [
  //     {
  //       id: "yourProducts",
  //       title: "Your Products",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/inventory/product"
  //     },
  //     {
  //       id: "addProducts",
  //       title: "Add Products",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/inventory/product/add"
  //     },
  //   ]
  // },
  // {
  //   id: "config",
  //   title: "Config",
  //   type: "collapse",
  //   icon: <Icon.Settings size={20} />,
  //   children: [
  //     {
  //       id: "banner",
  //       title: "Banner List",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/inventory/manage/banner-list"
  //     },
  //     {
  //       id: "categoryList",
  //       title: "Category List",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/inventory/manage/category-list"
  //     },
  //     {
  //       id: "appConfig",
  //       title: "App Config",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/order/manage/app-config"
  //     },
  //     {
  //       id: "userNotification",
  //       title: "User Notification",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/order/manage/user-notification"
  //     },
  //   ]
  // },
  {
    id: "products",
    title: "Items",
    type: "item",
    icon: <Inventory2Icon />,
    navLink: "/order/details",
    // children: [
    //   {
    //     id: "productDetails",
    //     title: "Items List",
    //     type: "item",
    //     icon: <Icon.Circle size={12} />,
    //     permissions: ["admin", "editor"],
    //     navLink: "/order/details"
    //   },
    // {
    //   id: "createProduct",
    //   title: "Create Product",
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["admin", "editor"],
    //   navLink: "/product/create"
    // },
    // {
    //   id: "comboProduct",
    //   title: "Combo Product",
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["admin", "editor"],
    //   navLink: "/product/combo"
    // }
    // ]
  },
  {
    id: "vendor",
    title: "Parties",
    type: "item",
    icon: <GroupIcon />,
    navLink: "/vendor/view",
    // children: [
    //   {
    //     id: "addVendor",
    //     title: "Add Parties",
    //     type: "item",
    //     icon: <Icon.Circle size={12} />,
    //     permissions: ["admin", "editor"],
    //     navLink: "/vendor/add"
    //   },
    //   {
    //     id: "viewVendors",
    //     title: "View Parties",
    //     type: "item",
    //     icon: <Icon.Circle size={12} />,
    //     permissions: ["admin", "editor"],
    //     navLink: "/vendor/view"
    //   },
    // ]
  },
  {
    id: "orders",
    title: "Orders",
    type: "item",
    icon: <Inventory2Icon />,
    navLink: "/orders",
  },
  {
    id: "sales",
    title: "Sales",
    type: "collapse",
    icon: <ShoppingBasketIcon />,
    // badge: "warning",
    // badgeText: "2",
    children: [
      {
        id: "salesItems",
        title: "Sales Items",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/sales/add"
      },
      // {
      //   id: "eCommerceDash",
      //   title: "eCommerce",
      //   type: "item",
      //   icon: <Icon.Circle size={12} />,
      //   permissions: ["admin"],
      //   navLink: "/ecommerce-dashboard"
      // }
    ]
  },
  {
    id: "purchase",
    title: "Purchase",
    type: "collapse",
    icon: <ShoppingCartIcon />,
    children: [
      // {
      //   id: "addPurchase",
      //   title: "Add Stock",
      //   type: "item",
      //   icon: <Icon.Circle size={12} />,
      //   permissions: ["admin", "editor"],
      //   navLink: "/purchase/add"
      // },
      {
        id: "viewPurchase",
        title: "Purchase Stocks",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/purchase/view"
      },
      {
        id: "viewPayments",
        title: "Payments Out",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/payment/view"
      },
    ]
  },
  {
    id: "proforma",
    title: "Proforma",
    type: "collapse",
    icon: <FactCheckIcon />,
    children: [
      {
        id: "viewProforma",
        title: "View Proforma",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/proforma/view"
      },
      {
        id: "addProforma",
        title: "Add Proforma",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/proforma/add"
      },
    ]
  },
  {
    id: "companyList",
    title: "Company List",
    type: "item",
    icon: <Icon.List />,
    navLink: "/company/list",
  },
  {
    id: "reports",
    title: "Reports",
    type: "collapse",
    icon: <AssessmentIcon />,
    // badge: "warning",
    // badgeText: "2",
    children: [
      {
        id: "Reports",
        title: "Reports",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/"
      },
      // {
      //   id: "eCommerceDash",
      //   title: "eCommerce",
      //   type: "item",
      //   icon: <Icon.Circle size={12} />,
      //   permissions: ["admin"],
      //   navLink: "/ecommerce-dashboard"
      // }
    ]
  },
  // {
  //   id: "payments",
  //   title: "Payments",
  //   type: "collapse",
  //   icon: <Icon.List size={20} />,
  //   children: [
  //     {
  //       id: "viewPayments",
  //       title: "View Payments",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/payment/view"
  //     },
  //     // {
  //     //   id: "viewPurchase",
  //     //   title: "View Stocks",
  //     //   type: "item",
  //     //   icon: <Icon.Circle size={12} />,
  //     //   permissions: ["admin", "editor"],
  //     //   navLink: "/purchase/view"
  //     // },
  //   ]
  // },
  // {
  //   id: "reports",
  //   title: "Reports",
  //   type: "collapse",
  //   icon: <Icon.FileText size={20} />,
  //   children: [
  //     {
  //       id: "salesReport",
  //       title: "Sales",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/report/sales"
  //     }
  //   ]
  // }
]

export default nTNavConfig
