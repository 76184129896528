import axios from "axios"
import config from "../../../configs/clientConfig";
import { history } from "../../../history"
import { toast } from "react-toastify"
import { productLoaded, productLoading } from "../product";

const ADD_VENDOR_URL = '/addVendor';
export const GET_VENDORS_URL = '/getAllVendors';

export const UPDATE_VENDOR = 'UPDATE_VENDOR';
export const GET_VENDORS = 'GET_VENDORS';
export const CLEAR_VENDORS = 'CLEAR_VENDORS';
export const GET_ALL_PROFORMA = 'GET_ALL_PROFORMA';
export const GET_PROFORMA_ITEMS_BY_ID = 'GET_PROFORMA_BY_ID';


const ADD_PROFORMA_URL = '/addProformaItems';
const GET_ALL_PROFORMA_ITEMS_URL = '/getProformaItems/all'
const GET_PROFORMA_ITEMS_BY_ID_URL = '/getProformaItems/'

export const addVendorData = (vendor, path) => {
    return dispatch => {
        productLoading();
        axios.post(config.apiServerHost + ADD_VENDOR_URL, vendor).
            then((response) => {
                console.log('Response addVendor', response);
                toast.success("Vendor added successfully!")
                if (path === '/vendor/add')
                    history.push('/vendor/view')

            }).catch((error) => {
                toast.error("Failed to create vendor", error);
            });
        productLoaded();
    }
}


export const updateVendor = (key, value) => {
    return dispatch => {
        dispatch({
            type: UPDATE_VENDOR,
            key: key,
            value: value
        })

    }
}

export const getVendors = () => {
    return dispatch => {
        dispatch(productLoading());
        axios.get(config.apiServerHost + GET_VENDORS_URL).then(response => {
            dispatch({
                type: GET_VENDORS,
                data: response.data
            })
            console.log('Response getVendors-', response.data)
            dispatch(productLoaded());
        })
    }
}

export const addProformaItems = (data) => {
    return dispatch => {
        console.log(data)
        axios.post(config.apiServerHost + ADD_PROFORMA_URL, data).
            then((response) => {
                console.log('Response purchase add', response);
                toast.success("Proforma details added successfully!")
                history.push('/order/details')
            }).catch((error) => {
                toast.error("Failed to add purchase items ", error);
            });
        // dispatch(productLoaded());
    }
}

export const getAllProforma = () => {
    return dispatch => {
        axios.get(config.apiServerHost + GET_ALL_PROFORMA_ITEMS_URL).
            then((response) => {
                dispatch({
                    type: GET_ALL_PROFORMA,
                    data: response.data
                })
                console.log('Proforma info', response.data);
            }).catch((error) => {
                toast.error("Error fetching Proforma info ", error);
            });
        // dispatch(productLoaded());
    }
}

export const getProformaItemsById = (id) => {
    return dispatch => {
        axios.get(config.apiServerHost + GET_PROFORMA_ITEMS_BY_ID_URL+id).
            then((response) => {
                dispatch({
                    type: GET_PROFORMA_ITEMS_BY_ID,
                    data: response.data
                })
                console.log('Proforma items', response.data);
            }).catch((error) => {
                toast.error("Error fetching Proforma items ", error);
            });
        // dispatch(productLoaded());
    }
}

export const clearVendors = () => {
    return dispatch => {
        dispatch({ type: CLEAR_VENDORS })
    }
}