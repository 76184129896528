import { GET_PAYMENT, CLEAR_PAYMENT } from '../../actions/payment/index';

const initialState = {
    paymentLoading: false,
    // payment: {
    //     purchase_payment_id: "",
    //     purchase_info_id: "",
    //     total: "",
    //     paid_amount: "",
    //     remaining: "",
    //     payment: "",
    //     payment_ts: ""
    // },
    paymentList: [],
}

const payment = (state = initialState, action) => {
    switch (action.type) {
        // case UPDATE_PURCHASE: {
        //     return state;
        //     // let { vendor } = state;
        //     // vendor[action.key] = action.value;
        //     // return {
        //     //     ...state,
        //     //     vendor: vendor
        //     // }
        // }
        case GET_PAYMENT: {
            return {
                ...state,
                paymentList: action.data.vendor_products
            }
        }
        case CLEAR_PAYMENT: {
            return {
                ...state,
                paymentList: []
            }
        }
        default:
            return state;
    }
}


export default payment;