import axios from "axios"
import config from "../../../configs/clientConfig";
import { history } from "../../../history"
import { toast } from "react-toastify"
import { productLoaded, productLoading } from "../product";

const ADD_VENDOR_URL = '/addVendor';
export const GET_VENDORS_URL = '/getAllVendors';
export const GET_COMPANIES_URL = '/list/companies';
export const GET_COMPANIES_NEW_URL = '/buyerCompanies';

export const UPDATE_VENDOR = 'UPDATE_VENDOR';
export const GET_VENDORS = 'GET_VENDORS';
export const CLEAR_VENDORS = 'CLEAR_VENDORS';

export const addVendorData = (vendor, path) => {
    return dispatch => {
        productLoading();
        axios.post(config.apiServerHost + ADD_VENDOR_URL, vendor).
            then((response) => {
                console.log('Response addVendor', response);
                toast.success("Vendor added successfully!")
                if (path === '/vendor/add')
                    history.push('/vendor/view')

            }).catch((error) => {
                toast.error("Failed to create vendor", error);
            });
        productLoaded();
    }
}


export const updateVendor = (key, value) => {
    return dispatch => {
        dispatch({
            type: UPDATE_VENDOR,
            key: key,
            value: value
        })

    }
}

export const getVendors = () => {
    return dispatch => {
        dispatch(productLoading());
        axios.get(config.apiServerHost + GET_VENDORS_URL).then(response => {
            dispatch({
                type: GET_VENDORS,
                data: response.data
            })
            console.log('Response getVendors-', response.data)
            dispatch(productLoaded());
        })
    }
}

export const clearVendors = () => {
    return dispatch => {
        dispatch({ type: CLEAR_VENDORS })
    }
}