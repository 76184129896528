import {
  GET_SHIPROCKET_ORDER_STATUS_LIST,
  GET_ORDERS_DETAILS,
  TOGGLE_ORDER_ID_FOR_CSV,
  SET_CSV_DATA,
  CLEAR_ORDER_LIST
} from "../../actions/order/index";

const initialState = {
  shiprocketOrderStatusList: [],
  orderListReceived: false,
  orderList: [],
  orderIdForCsv: [],
  csvData: [],
  csvHeaders: ["*Order Id","*Order Date as dd-mm-yyyy hh:MM","*Channel","*Payment Method(COD/Prepaid)","*Customer First Name","*Customer Last Name","*Email","*Customer Mobile","Customer Alternate Mobile","*Shipping Address Line 1","*Shipping Address Line 2","*Shipping Address Country","*Shipping Address State","*Shipping Address City","*Shipping Address Postcode","Billing Address Line 1","Billing Address Line 2","Billing Address Country","Billing Address State","Billing Address City","Billing Address Postcode","*Master SKU","*Product Name","*Product Quantity","Tax %","*Selling Price(Per Unit Item, Inclusive of Tax)","Discount(Per Unit Item)","Shipping Charges(Per Order)","COD Charges(Per Order)","Gift Wrap Charges(Per Order)","Total Discount (Per Order)","*Length (cm)","*Breadth (cm)","*Height (cm)","Weight Of Shipment(kg)","Send Notification(True/False)","Comment","HSN Code","Pickup Location Id","Reseller Name","Company Name","Ewaybill No","latitude","longitude"]
};

const order = (state = initialState, action) => {
  switch (action.type) {
    case GET_SHIPROCKET_ORDER_STATUS_LIST: {
      return {
        ...state,
        shiprocketOrderStatusList: action.data.statusList
      };
    }
    case GET_ORDERS_DETAILS: {
      return {
        ...state,
        orderList: action.data.orderList,
        orderListReceived: true
      };
    }

    case CLEAR_ORDER_LIST: {
      return {
        ...state,
        orderListReceived: false
      };
    }

    case TOGGLE_ORDER_ID_FOR_CSV: {
      let { orderIdForCsv } = state;
      if (action.selection) {
        orderIdForCsv.push(action.order_id);
      } else {
        orderIdForCsv = orderIdForCsv.filter(value => value !== action.order_id);
      }

      return {
        ...state,
        orderIdForCsv: orderIdForCsv
      }
    }

    case SET_CSV_DATA: {
      return {
        ...state,
        csvData: action.data
      }
    }

    default:
      return state;
  }
};

export default order;
